import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: ${props => props.width || '100%'};
  max-width: 100%;
`;

const Outer = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-top: ${props => 100 / props.aspectRatio}%;
`;

const Inner = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

export default ({ style, children, width, aspectRatio }) => (
  <Wrapper width={width} style={style}>
    <Outer aspectRatio={aspectRatio}>
      <Inner>{children}</Inner>
    </Outer>
  </Wrapper>
);
