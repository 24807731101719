import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import AspectRatioContainer from './AspectRatioContainer';

const ImgContainer = styled.div`
  background-color: black;
  ${props =>
    props.cover
      ? `
          height: 100%;
          width: 100%;
        `
      : ''}
`;

const InnerImage = styled.img`
  display: block;
  margin: 0 auto;
  width: 100%;
  height: ${props => (props.cover ? '100%' : 'auto')};
  object-fit: ${props => (props.cover ? 'cover' : 'fill')};
  opacity: ${props => (props.loaded ? 1 : 0)};
  transition: ${props => (props.known ? 'none' : 'all 0.3s ease-in')};
`;

export default class WPImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { known: false, loaded: false };
    this.handleLoad = this.handleLoad.bind(this);
    this.convertSrcSet = this.convertSrcSet.bind(this);
  }

  handleLoad() {
    this.setState({ loaded: true });
  }

  convertSrcSet(srcObj) {
    if (!srcObj) return undefined;

    if (srcObj['thumbnail-width']) {
      return `
      ${srcObj.thumbnail} ${srcObj['thumbnail-width']}w,
      ${srcObj.medium} ${srcObj['medium-width']}w,
      ${srcObj.medium_large} ${srcObj['medium_large-width']}w,
      ${srcObj.large} ${srcObj['large-width']}w,
      ${this.props.src} 1920w
    `;
    }
  }

  componentDidMount() {
    this.img = new Image();
    this.img.src = this.props.src;
    if (this.img.complete) {
      this.setState({ known: true, loaded: true });
      return;
    } else {
      this.setState({ known: false });
    }
    this.img.srcset = this.convertSrcSet(this.props.srcSet) || this.props.src;
    this.img.addEventListener('load', this.handleLoad);
  }

  componentWillUnmount() {
    this.img.removeEventListener('load', this.handleLoad);
  }

  render() {
    return this.props.empty ? (
      <AspectRatioContainer aspectRatio={1.778} />
    ) : (
      <ImgContainer cover={this.props.cover} className={this.props.className}>
        <InnerImage
          src={this.props.src}
          srcSet={this.convertSrcSet(this.props.srcSet) || this.props.src}
          sizes={
            this.props.sizes ||
            '(max-width: 150px) 150px, (max-width: 300px) 300px, (max-width: 1024px) 1024px, 1920px'
          }
          known={this.state.known}
          loaded={this.state.loaded}
          alt={this.props.alt}
          cover={this.props.cover}
        />
      </ImgContainer>
    );
  }
}

WPImage.propTypes = {
  cover: PropTypes.bool,
  src: PropTypes.string.isRequired,
  srcset: PropTypes.string,
  alt: PropTypes.string,
};
