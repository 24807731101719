import { Grid } from '@material-ui/core';
import React from 'react';

import ProjectCard from './ProjectCard';

const findSize = index => {
  if (index % 6 < 3) {
    return 4;
  } else if (index % 6 === 3) {
    return 12;
  } else {
    return 6;
  }
};

const generateProjects = (proj, i, arr) => {
  const size = findSize(i);
  return (
    <Grid item xs={12} sm={size} key={proj.ID}>
      <ProjectCard
        id={proj.ID}
        src={proj.featured_image}
        srcSet={proj.sizes}
        sizes={`(max-width: 600px) 100vw, ${
          size === 6 ? '50vw' : size === 4 ? '33vw' : '100vw'
        }`}
        title={proj.title}
        categories={proj.metadata.categories}
        aspectRatio={size === 12 ? 1.778 : undefined}
      />
    </Grid>
  );
};

export default ({ projects }) => (
  <Grid container spacing={24}>
    {projects.map(generateProjects)}
  </Grid>
);
