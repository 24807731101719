import { Grid } from '@material-ui/core';
import React from 'react';

import Layout from '../Layout';
import MainWorkGrid from '../MainWorkGrid';
import Narrow from '../Narrow';
import PageTitle from '../PageTitle';
import ProjectCard from '../ProjectCard';
import Subheading from '../Subheading';
import Textblob from '../Textblob';

export default ({ pageContext: { searchIndex, service, projectsMeta } }) => {
  const {
    title,
    acf: { description },
  } = service;

  const projectHasService = (project, service) => {
    if (!project.metadata.services) {
      return undefined;
    }
    return (
      project.metadata.services.filter(
        projService => projService.post_title === service
      ).length > 0
    );
  };

  const myProjects = projectsMeta.filter(project =>
    projectHasService(project, title.rendered)
  );

  const otherProjects = projectsMeta.filter(
    project => !projectHasService(project, title.rendered)
  );

  return (
    <>
      <Layout background={false} searchIndex={searchIndex}>
        <PageTitle>{title.rendered}</PageTitle>
        {description && (
          <Narrow>
            <Textblob>{description}</Textblob>
          </Narrow>
        )}
        <Grid container spacing={24}>
          {myProjects.map(proj => (
            <Grid item xs={12} sm={6} md={4} key={proj.ID}>
              <ProjectCard
                id={proj.ID}
                src={proj.featured_image}
                srcSet={proj.sizes}
                sizes={`(max-width: 600px) 100vw, (max-width: 960px) 50vw, 33vw`}
                title={proj.title}
                categories={proj.metadata.categories}
              />
            </Grid>
          ))}
        </Grid>
        <Subheading>All other work</Subheading>
        <MainWorkGrid projects={otherProjects} />
      </Layout>
    </>
  );
};
